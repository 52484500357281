import React from 'react';

// Component Imports
import Header from '../../components/shared/Header/Header';
import Footer from '../../components/shared/Footer/Footer';
import BackToTop from '../../components/shared/BackToTop/BackToTop';

// Style Imports
import './Default.scss';

const DefaultLayout = (props) => {
  return (
    <div className='page-wrapper'>
      <Header />

      <main className='page-body'>
        {props.children}
      </main>

      <Footer />

      <BackToTop />
    </div>
  );
};

export default DefaultLayout;