/* eslint jsx-a11y/no-noninteractive-element-to-interactive-role: 0 */
import React, { useState } from 'react';
import { Link } from 'gatsby';

// Style imports
import './Header.scss';

// Image imports
import Logo from '../../../assets/images/content/logo.svg';

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <header className='header'>
        <div className='header__container container'>
          <Link
            className='header__logo-link'
            to='/'
            onClick={() => { window.scrollTo(0, 0); }}
          >
            <img className='header__logo' src={Logo} alt='ParkyGames logo' />
            <span className='accessible'>Homepage</span>
          </Link>

          <button className='header__hamburger' onClick={toggleMenu}>
            <span className='accessible'>Toggle mobile navigation menu</span>
          </button>

          <nav className={`header__navigation${menuOpen ? ' is-active' : ''}`} aria-label='Main website navigation'>
            <div className='header__navigation-inner'>
              <button className='header__close-button' onClick={toggleMenu}>
                <span className='accessible'>Close</span>
              </button>

              <ul className='header__list list--reset' role='menu'>
                <li className='header__list-item' role='none'>
                  <Link
                    className='header__link'
                    role='menuitem'
                    to='/'
                    onClick={() => { 
                      window.scrollTo(0, 0);
                      toggleMenu();
                    }}
                  >
                    Home
                  </Link>
                </li>

                <li className='header__list-item' role='none'>
                  <Link
                    className='header__link'
                    role='menuitem'
                    to='/blog'
                    onClick={() => {
                      window.scrollTo(0, 0);
                      toggleMenu();
                    }}
                  >
                    Blog
                  </Link>
                </li>

                <li className='header__list-item' role='none'>
                  <Link
                    className='header__link'
                    role='menuitem'
                    to='/about'
                    onClick={() => {
                      window.scrollTo(0, 0);
                      toggleMenu();
                    }}
                  >
                    About
                  </Link>
                </li>

                <li className='header__list-item' role='none'>
                  <Link
                    className='header__link'
                    role='menuitem'
                    to='/services'
                    onClick={() => {
                      window.scrollTo(0, 0);
                      toggleMenu();
                    }}
                  >
                    Services
                  </Link>
                </li>

                <li className='header__list-item' role='none'>
                  <Link
                    className='header__link'
                    role='menuitem'
                    to='/faqs'
                    onClick={() => {
                      window.scrollTo(0, 0);
                      toggleMenu();
                    }}
                  >
                    FAQs
                  </Link>
                </li>
                
                <li className='header__list-item' role='none'>
                  <Link
                    className='header__link'
                    role='menuitem'
                    to='/contact'
                    onClick={() => {
                      window.scrollTo(0, 0);
                      toggleMenu();
                    }}
                  >
                    Contact
                  </Link>
                </li>
              </ul>
            </div>
          </nav>
        </div>
    </header>
  );
};

export default Header;