/* eslint jsx-a11y/no-noninteractive-element-to-interactive-role: 0 */
import React from 'react';
import { Link } from 'gatsby';

// Style imports
import './Footer.scss';

// Asset imports
import Facebook from '../../../assets/images/icons/facebook-white.svg';
import Twitter from '../../../assets/images/icons/twitter-white.svg';
import Instagram from '../../../assets/images/icons/instagram-white.svg';
import Youtube from '../../../assets/images/icons/youtube-white.svg';
import Patreon from '../../../assets/images/icons/patreon-white.svg';

const Footer = () => {
  return (
    <footer className='footer'>
      <div className='footer__inner'>
        <div className='footer__container container'>
          <nav className='footer__navigation' aria-label='Social media'>
            <ul className='footer__social'>
              <li className='footer__social-item'>
                <a className='footer__social-link' href='http://www.youtube.com/ParkyGames/'>
                  <img className='footer__social-icon' src={Youtube} alt='' aria-hidden='true' />
                  <span className='accessible'>YouTube</span>
                </a>
              </li>

              <li className='footer__social-item'>
                <a className='footer__social-link' href='https://www.instagram.com/parky_games/'>
                  <img className='footer__social-icon' src={Instagram} alt='' aria-hidden='true' />
                  <span className='accessible'>Instagram</span>
                </a>
              </li>

              <li className='footer__social-item'>
                <a className='footer__social-link' href='https://twitter.com/parkygames'>
                  <img className='footer__social-icon' src={Twitter} alt='' aria-hidden='true' />
                  <span className='accessible'>Twitter</span>
                </a>
              </li>

              <li className='footer__social-item'>
                <a className='footer__social-link' href='https://www.facebook.com/parkygames'>
                  <img className='footer__social-icon' src={Facebook} alt='' aria-hidden='true' />
                  <span className='accessible'>Facebook</span> 
                </a>
              </li>

              <li className='footer__social-item'>
                <a className='footer__social-link' href='https://www.patreon.com/ParkyGames?ty=h'>
                  <img className='footer__social-icon' src={Patreon} alt='' aria-hidden='true' />
                  <span className='accessible'>Patreon</span> 
                </a>
              </li>
            </ul>
          </nav>

          <nav className='footer__navigation' aria-label='Sitemap'>
            <ul className='footer__list list--reset' role='menu'>
              <li className='footer__list-item' role='none'>
                <Link
                  className='footer__link'
                  role='menuitem'
                  to='/'
                  onClick={() => { window.scrollTo(0, 0); }}
                >
                  Home
                </Link>
              </li>

              <li className='footer__list-item' role='none'>
                <Link
                  className='footer__link'
                  role='menuitem'
                  to='/blog'
                  onClick={() => { window.scrollTo(0, 0); }}
                >
                  Blog
                </Link>
              </li>

              <li className='footer__list-item' role='none'>
                <Link
                  className='footer__link'
                  role='menuitem'
                  to='/about'
                  onClick={() => { window.scrollTo(0, 0); }}
                >
                  About
                </Link>
              </li>

              <li className='footer__list-item' role='none'>
                <Link
                  className='footer__link'
                  role='menuitem'
                  to='/services'
                  onClick={() => { window.scrollTo(0, 0); }}
                >
                  Services
                </Link>
              </li>

              <li className='footer__list-item' role='none'>
                <Link
                  className='footer__link'
                  role='menuitem'
                  to='/faqs'
                  onClick={() => { window.scrollTo(0, 0); }}
                >
                  FAQs
                </Link>
              </li>
              
              <li className='footer__list-item' role='none'>
                <Link
                  className='footer__link'
                  role='menuitem'
                  to='/contact'
                  onClick={() => { window.scrollTo(0, 0); }}
                >
                  Contact
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      </div>

      <div className='footer__inner'>
        <div className='footer__container container'>
          <ul className='footer__list list--reset'>
            <li className='footer__list-item'>
              <Link
                className='footer__link'
                to='/terms-and-conditions'
                onClick={() => { window.scrollTo(0, 0); }}
              >
                Terms &amp; conditions
              </Link>
            </li>

            <li className='footer__list-item'>
              <Link
                className='footer__link'
                to='/privacy-policy'
                onClick={() => { window.scrollTo(0, 0); }}
              >
                Privacy policy
              </Link>
            </li>

            <li className='footer__list-item'>
              <Link
                className='footer__link'
                to='/cookie-policy'
                onClick={() => { window.scrollTo(0, 0); }}
              >
                Cookie policy
              </Link>
            </li>
          </ul>
          <small className='footer__copyright'>&copy; 2010-2021 ParkyGames - All Rights Reserved</small>
        </div>
      </div>
    </footer>
  );
}

export default Footer;