import React from 'react';
import PropTypes from 'prop-types';
import {TinyButton as ScrollUpButton} from "react-scroll-up-button";

// Style imports
import './BackToTop.scss';

const BackToTop = ({
    style,
}) => {

    const defaultStyle = {
        backgroundColor: 'rgba(24, 54, 88, .75)',
        bottom: '20px',
        borderRadius: '0 15px',
        display: 'flex',
        height: '40px',
        right: '20px',
        width: '40px',
        transition: 'background-color ease .3s, opacity ease .3s',
        zIndex: '5',
        ...style,
    };

    return (
        <>
            <ScrollUpButton
                ShowAtPosition={200}
                EasingType='easeOutCubic'
                AnimationDuration={500}
                style={defaultStyle}
                ToggledStyle={defaultStyle}
                ContainerClassName="back-to-top"
            />
        </>
    );
};

BackToTop.propTypes = {
    style: PropTypes.object,
};

export default BackToTop;
